import {NgModule} from '@angular/core';
import {MomentPipe} from './moment.pipe';
import {ObjectKeysPipe} from './objectKeys.pipe';
import {SafeHtmlPipe, SafeUrlPipe} from './safe.pipe';
import {ObjectStringFilterPipe} from './objectStringFilter.pipe';
import {StringFilterPipe} from './stringFilter.pipe';
import {PhoneFormatPipe} from './phoneFormat.pipe';

@NgModule({
    declarations: [
        MomentPipe,
        ObjectKeysPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        ObjectStringFilterPipe,
        StringFilterPipe,
        PhoneFormatPipe,
    ],
    imports: [],
    exports: [
        MomentPipe,
        ObjectKeysPipe,
        SafeHtmlPipe,
        SafeUrlPipe,
        ObjectStringFilterPipe,
        StringFilterPipe,
        PhoneFormatPipe,
    ],
})
export class PipesModule {}
