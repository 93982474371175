import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import * as globalPaths from 'src/app/pathConfig';
const API_URL = globalPaths.API_URL;
const APP_URL = globalPaths.APP_URL;
if (environment.production) {
    Sentry.init({
        dsn: 'https://79e1c04547cc659a80eb4c2d2dd713b5@o4506470979993600.ingest.sentry.io/4506675985121280',
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: [new RegExp('^' + API_URL + '/')],
            }),
            new Sentry.Replay({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    enableProdMode();
}

if (environment.production && API_URL === 'https://api.tenantpay.com/api/v2') {
    const googleMapsApiUrl = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsApiKey}&libraries=geometry,places&callback=Function.prototype`;
    const script = document.createElement('script');
    script.src = googleMapsApiUrl;
    script.defer = true;
    script.async = true;
    document.head.appendChild(script);
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then((success) => console.log('Bootstrap success'))
    .catch((err) => console.error(err));
